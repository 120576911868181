.containerPlatformSelector {
    width: 100%;
    height: 92vh;
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    overflow: scroll;
}

.platformCard {
    border-radius: 1em;
    min-width: 46%;
    height: 40%;
    margin: 2% 2%;
    padding: 1em;
    box-shadow: 0.3em 1em 1em lightgray;
    text-align: center;

    display: flex;
    flex-wrap: wrap;

    &--title {
        width: 100%;
        height: 20%;
    }


    &--content {
        height: 60%;
        width: 100%;
    }

    &--actions {
        height: 20%;
        width: 100%;
    }
}

.platformPopup {
    &--openTrigger {
        position: absolute;
        top: 9vh;
        right: 3vw;
    }
}

@media (max-width: 1200px) {
    .platformCard {
        min-width: 96%;
        margin: 2% 2%;
    }
}