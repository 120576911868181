@use '../abstracts';

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-size: 14px;
}

html,
body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    font-family: 'Comfortaa', Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-track {
    border-radius: 1em;
    box-shadow: inset 0 0 10px lightgray;
}

::-webkit-scrollbar-thumb {
    border-radius: 1em;
    background-color: lightgray;
}

#root {
    width: 100%;
    height: 100%;
}

.full-view {
    width: 100%;
    height: 93vh;
    display: flex;
    flex-direction: row;
}

.contentBody {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    overflow: auto;

}